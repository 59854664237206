import classNames from "classnames";
import { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGlobalStore, useInstallationStore } from "src/store";
import {
  FALLBACK_BASE_PRICE,
  FALLBACK_OUTSIDE_LIFT_PRICE,
  FALLBACK_PERMISSION_PRICE,
} from "src/utils/constants";
import { FormSchema } from "src/utils/formSchema";
import ReactSelect from "../common/react-select";

type Props = {
  form: UseFormReturn<FormSchema, unknown>;
};

export default function CitySelector({ form }: Props) {
  const { t } = useTranslation();
  const { global, setBasePrice, setPermissionPrice, setWithOutsideLiftPrice } =
    useGlobalStore();
  const {
    installationDetails,
    setInstallationDetails,
    resetInstallationDetails,
  } = useInstallationStore();

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div>
      <div
        className={classNames("floating-select", {
          focused: isFocused || !!installationDetails.city,
        })}
      >
        <Controller
          control={form.control}
          name="city"
          render={({ field: { ref, onChange }, formState: { errors } }) => (
            <ReactSelect
              ref={ref}
              id="city"
              placeholder=" "
              options={global.cities}
              isLoading={!global.cities || !global.cities.length}
              defaultValue={global.cities.filter(
                (city) => city.value === installationDetails.city?.id
              )}
              onFocus={handleFocus}
              onBlur={() => {
                handleBlur();
              }}
              onChange={(e: any) => {
                resetInstallationDetails();
                const selectedCity = global.cities.find(
                  (city) => city.value === e!.value
                )!;
                const formatedCity = {
                  value: selectedCity.value,
                  label: selectedCity.label,
                  basePrice: selectedCity.basePrice,
                  withOutsideLiftPrice: selectedCity.withOutsideLiftPrice,
                  id: selectedCity.value,
                };

                setBasePrice(selectedCity.basePrice || FALLBACK_BASE_PRICE);
                setPermissionPrice(
                  selectedCity.permissionPrice || FALLBACK_PERMISSION_PRICE
                );
                setWithOutsideLiftPrice(
                  selectedCity.withOutsideLiftPrice ||
                    FALLBACK_OUTSIDE_LIFT_PRICE
                );
                e && setInstallationDetails({ city: formatedCity });
                return onChange(global.cities.map((option) => option.value));
              }}
              classNamePrefix="select"
              aria-invalid={!!errors.city?.message && global.showRequired}
            />
          )}
        />
        <label htmlFor="city" className="input-label line-height select-label">
          {t("installations:inputs:city:placeholder")}
        </label>
        <fieldset className="fieldset">
          <legend>{t("installations:inputs:city:placeholder")}</legend>
        </fieldset>
      </div>
      <p
        className={classNames({
          required: true,
          "display-none": !global.showRequired,
        })}
      >
        {form.formState.errors.city?.message}
      </p>
    </div>
  );
}
