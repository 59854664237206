import { RangePickerSelectedDays } from "src/components/DateRange";
import { LENGTH_OF_BOARD, PURPOSE_OPTIONS } from "src/utils/constants";
import create from "zustand";

type City = {
  value: string;
  label: string;
  basePrice: number;
  withOutsideLiftPrice: number;
  id: string;
};
interface InstallationDetailsState {
  installationDetails: {
    city: City | null;
    streetAndHouseNumber: string;
    zipCode: string;
    purpose: string;
    dateRange: {
      from: string;
      to: string;
    };
    permissionForElevator: boolean;
    withPermission: boolean;
    twoSiteBlock: boolean;
    withOutsideLift: boolean;
    timeFrom: string;
    timeTill: string;
    lengthOfBoard: string;
  };
  setInstallationDetails: (data: {
    [key: string]: string | boolean | RangePickerSelectedDays | City;
  }) => void;

  resetInstallationDetails: () => void;
}

const initialState = {
  city: null,
  streetAndHouseNumber: "",
  zipCode: "",
  purpose: PURPOSE_OPTIONS["umzug"].value,
  dateRange: {
    from: "",
    to: "",
  },
  permissionForElevator: false,
  withPermission: true,
  twoSiteBlock: false,
  withOutsideLift: false,
  timeFrom: "07:00",
  timeTill: "18:00",
  lengthOfBoard: LENGTH_OF_BOARD["15m"],
};

const useInstallationStore = create<InstallationDetailsState>((set) => ({
  installationDetails: initialState,
  setInstallationDetails: (data: {
    [key: string]: string | boolean | RangePickerSelectedDays | City;
  }) =>
    set((state) => ({
      ...state,
      installationDetails: {
        ...state.installationDetails,
        ...data,
      },
    })),

  resetInstallationDetails: () =>
    set((state) => ({
      ...state,
      installationDetails: {
        ...initialState,
        purpose: state.installationDetails.purpose,
      },
    })),
}));

export { useInstallationStore };
