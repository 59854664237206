import dayjs from "dayjs";
import {
  toast,
  ToastContent,
  ToastPosition,
  TypeOptions,
} from "react-toastify";
import i18n from "src/i18n";

export const calculatePerDayAmount = (
  time: number,
  basePriceLocal: number,
  day: string
) => {
  let totalPrice = basePriceLocal;

  // check if it's monday and not the 6th day from the current day
  if (dayjs(day).day() === 1 && time !== -5) {
    time += 1;
  }

  if (time > -5) {
    return 0;
  }

  // logic for the days of the week
  // if (time === -5) {
  //   totalPrice += 0.6 * totalPrice;
  // } else if (time === -6) {
  //   totalPrice += 0.5 * totalPrice;
  // } else if (time === -7) {
  //   totalPrice += 0.4 * totalPrice;
  // } else if (time === -8) {
  //   totalPrice += 0.3 * totalPrice;
  // } else if (time === -9) {
  //   totalPrice += 0.2 * totalPrice;
  // } else if (time === -10) {
  //   totalPrice += 0.1 * totalPrice;
  // } else if (time < -10 && time >= -24) {
  //   // do nothing
  // } else if (time < -24 && time >= -38) {
  //   totalPrice -= 0.1 * totalPrice;
  // } else if (time < -38 && time > -53) {
  //   totalPrice -= 0.2 * totalPrice;
  // } else {
  //   totalPrice -= 0.3 * totalPrice;
  // }

  return totalPrice;
};

export const getExtraDays = (from: string, to: string) => {
  return dayjs().diff(from, "day") - dayjs().diff(to, "day");
};

export const getNextYearFromNow = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1));
};

export const getDaysBetweenDates = (
  start: Date,
  end: Date,
  dayName: string
): string[] => {
  let result = [];
  let days: any = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
  let day = days[dayName.toLowerCase().substr(0, 3)];
  // Copy start date
  let current = new Date(start);
  // Shift to next of required days
  current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));
  // While less than end date, add dates to result array
  while (current < end) {
    result.push(new Date(+current));
    current.setDate(current.getDate() + 7);
  }

  if (result.length) {
    let stringedResult = result.map((res: any) => {
      return dayjs(res).format("YYYY-MM-DD");
    });

    return stringedResult;
  } else {
    return [];
  }
};

export const showToast = (
  message: string,
  type: TypeOptions | undefined = "success",
  position: ToastPosition | undefined = "top-center"
) => {
  toast(i18n.t(`warnings:${message}`) as ToastContent<unknown>, {
    type,
    position,
  });
};

export const germanDateFormat = (date: string) => {
  const dateArray = date.split("-");
  const germanDate = `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
  return germanDate;
};

export const getAllDaysIn4Months = (year: number, month: number) => {
  let dates = [];

  for (let i = 0; i < 4; i++) {
    dates.push(new Date(year, month + i, 1));
  }

  let result: string[] = [];

  // Jan will be 0 in getMonth but 12 in the month so
  function getMonthRecursion(month: number) {
    if (month === 11) {
      return 0;
    } else {
      return month + 1;
    }
  }

  let aMonthLater = getMonthRecursion(month);
  let twoMonthLater = getMonthRecursion(aMonthLater);
  let threeMonthLater = getMonthRecursion(twoMonthLater);
  let fourMonthLater = getMonthRecursion(threeMonthLater);
  let fiveMonthLater = getMonthRecursion(fourMonthLater);
  dates.forEach((date) => {
    while (
      date.getMonth() === month ||
      date.getMonth() === aMonthLater ||
      date.getMonth() === twoMonthLater ||
      date.getMonth() === threeMonthLater ||
      date.getMonth() === fourMonthLater ||
      date.getMonth() === fiveMonthLater
    ) {
      result.push(dayjs(new Date(date)).format("YYYY-MM-DD"));
      date.setDate(date.getDate() + 1);
    }
  });

  return result;
};

export const getAllTime = (from: number, till: number) => {
  let listOfHours = [];

  for (let i = from; i <= till; i++) {
    let hour = i.toString();
    // check if it's a single digit, if yes add a 0 to it
    if (hour.length === 1) {
      hour = `0${hour}`;
    }

    listOfHours.push(`${hour}:00`);
  }
  return listOfHours;
};

/**
 * @param date Start date of dates to be blocked. Default to today.
 * @returns Array of dates blocked
 */
export const blockDatesAccordingToDay = (date = dayjs()) => {
  const day = date.day();
  let noOfDaysToBlock = 6; // Block given day and 5 upcoming days

  if (day === 5) {
    // Block given day and 7 upcoming days if given day is Friday
    noOfDaysToBlock = 8;
  }

  if (day === 6) {
    // Block given day and 6 upcoming days if given day is Saturday
    noOfDaysToBlock = 7;
  }

  const blockedDates = Array.from(
    { length: noOfDaysToBlock },
    (_, i) => -i
  ).map((day) => dayjs(date).subtract(day, "day").format("YYYY-MM-DD"));
  return blockedDates;
};

export const formattedAmount = (amount: number, decimal: number = 2) => {
  return Number(amount.toFixed(decimal));
};
