import classNames from "classnames";
import { Controller, UseFormReturn } from "react-hook-form";
import { useInstallationStore } from "src/store";
import { useFilesStore } from "src/store/files";
import { FormSchema } from "src/utils/formSchema";
import { FileUploader } from "./FileSelector";

export default function UploadComponent({
  form,
}: {
  form: UseFormReturn<FormSchema, unknown>;
}) {
  const { installationDetails } = useInstallationStore();
  const { setFiles } = useFilesStore();
  const isDateEmpty = !installationDetails.dateRange.from;

  return (
    <div className="row">
      <div className="col-12">
        <Controller
          control={form.control}
          name="files"
          render={({ field: { onChange, name } }) => {
            const onFileChange = (files: File[]) => {
              setFiles(files);
              onChange(files);
            };
            return (
              <FileUploader
                onChange={onFileChange}
                form={form}
                name={name}
                disabled={isDateEmpty}
              />
            );
          }}
        />
        <p
          className={classNames({
            required: true,
            "display-none": !form.formState.errors.files,
          })}
        >
          {form.formState.errors.files?.message as string}
        </p>
      </div>
    </div>
  );
}
