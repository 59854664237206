import dayjs from "dayjs";
import {
  useBasePrice,
  usePermissionPrice,
  useWithOutsideLiftPrice,
} from "src/hooks/helperHooks";
import { useInstallationStore } from "src/store";
import { useDiscountStore } from "src/store/discount";
import { extraDayPrice, TWO_SIDE_BLOCKING_PRICE } from "src/utils/constants";
import {
  calculatePerDayAmount,
  formattedAmount,
  getExtraDays,
} from "src/utils/helpers";

export function usePriceCalculation() {
  const { installationDetails } = useInstallationStore();
  const basePrice = useBasePrice();
  const permissionPrice = usePermissionPrice();
  const withOutsideLiftPrice = useWithOutsideLiftPrice();
  const { discountPercentage } = useDiscountStore();

  const totalBasePrice = calculatePerDayAmount(
    dayjs().diff(installationDetails.dateRange.from, "day"),
    basePrice,
    installationDetails.dateRange.from
  );

  const extraDays =
    getExtraDays(
      installationDetails.dateRange.from,
      installationDetails.dateRange.to
    ) || 0;

  const totalExtraDaysPrice = extraDays * extraDayPrice;

  const totalTwoSideBlockingPrice = installationDetails.twoSiteBlock
    ? TWO_SIDE_BLOCKING_PRICE
    : 0;

  const totalWithOutsideLiftPrice = installationDetails.withOutsideLift
    ? withOutsideLiftPrice
    : 0;

  const totalPermissionPrice = installationDetails.withPermission
    ? permissionPrice
    : 0;

  const discountAblePrice =
    totalBasePrice +
    totalExtraDaysPrice +
    totalTwoSideBlockingPrice +
    totalWithOutsideLiftPrice;

  const discountAmount = formattedAmount(
    discountAblePrice * (discountPercentage / 100)
  );

  const priceAfterDiscount = discountAblePrice - discountAmount;
  const grandTotal = formattedAmount(priceAfterDiscount + totalPermissionPrice);

  return {
    totalBasePrice,
    extraDays,
    totalExtraDaysPrice,
    totalTwoSideBlockingPrice,
    totalPermissionPrice,
    totalWithOutsideLiftPrice,
    discountAmount,
    grandTotal,
  };
}
