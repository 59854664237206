import classNames from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useInstallationStore, useNumberOfDaysStore } from "src/store";
import { numberOfDaysSelection } from "src/utils/constants";
import ReactSelect from "../common/react-select";

export type RangePickerSelectedDays = {
  from: string;
  to: string;
};

type Props = {
  currentStep: number;
};

const daysDropdownValues = numberOfDaysSelection();

export default function NumberOfDaysSelector({ currentStep }: Props) {
  const { installationDetails, setInstallationDetails } =
    useInstallationStore();
  const { numberOfDays, setNumberOfDays } = useNumberOfDaysStore();
  const { t } = useTranslation();

  const isDateEmpty = !installationDetails.dateRange.from;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  // TODO: This is to not break existing code.
  // numberOfDays is not in formSchema yet.
  const internalForm = useForm();

  return (
    <div>
      <div
        className={classNames("floating-select", {
          focused: isFocused || !!numberOfDays,
        })}
      >
        <Controller
          control={internalForm.control}
          name="numberOfDays"
          render={({ field: { ref, onChange } }) => (
            <>
              <ReactSelect
                ref={ref}
                id="numberOfDays"
                options={daysDropdownValues}
                value={numberOfDays}
                isDisabled={isDateEmpty}
                className={classNames({
                  "disabled-select": isDateEmpty,
                })}
                onFocus={handleFocus}
                onBlur={() => {
                  handleBlur();
                }}
                onChange={(e: any) => {
                  let dateRange = {
                    from: installationDetails.dateRange.from,
                    to: e
                      ? dayjs(installationDetails.dateRange.from)
                          .add(+e.value - 1, "day")
                          .format("YYYY-MM-DD")
                      : "",
                  };
                  setInstallationDetails({
                    dateRange,
                  });

                  e && setNumberOfDays(e);
                  return onChange(dateRange);
                }}
                classNamePrefix="select"
              />
            </>
          )}
        />
        <label
          htmlFor="numberOfDays"
          className="input-label line-height select-label"
        >
          {currentStep === 1
            ? t("installations:inputs:numberOfDays:labelStepOne")
            : t("installations:inputs:numberOfDays:labelStepTwo")}
        </label>
        <fieldset className="fieldset">
          <legend>
            {currentStep === 1
              ? t("installations:inputs:numberOfDays:labelStepOne")
              : t("installations:inputs:numberOfDays:labelStepTwo")}
          </legend>
        </fieldset>
      </div>
      <p className="display-none"></p>
    </div>
  );
}
