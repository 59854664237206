import DebounceControl from "debounce-control";
import { FC, useState } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAsyncFn } from "src/hooks";
import { CustomerResponse, requestCustomer } from "src/services/payment";
import { useInstallationStore } from "src/store";
import { useDiscountStore } from "src/store/discount";
import { FormSchema } from "src/utils/formSchema";
import { AnimatedInput, AnimatedTextarea } from "./common/animated-input";
import UploadComponent from "./form/FileUploadControl";
import InputLoading from "./form/InputLoading";
import { FormControl, FormField, FormItem, FormMessage } from "./ui/form";
import { AnimatedSelect } from "./AnimatedSelect/AnimatedSelect";

type ContactDetailsFormProps = {
  form: UseFormReturn<FormSchema, unknown>;
  setCodeValid: React.Dispatch<React.SetStateAction<boolean>>;
};

const ContactDetailsForm: FC<ContactDetailsFormProps> = ({
  form,
  setCodeValid,
}) => {
  const { t } = useTranslation();
  const { installationDetails } = useInstallationStore();

  const isDateEmpty = !installationDetails.dateRange.from;

  return (
    <div className="checkout-form">
      <div className="border">
        <div className="contact-form">
          <div className="form-title">{t("contactDetails:title")}</div>
          <div className="row ">
            <FormField
              control={form.control}
              name="salutation"
              render={({ field }) => (
                <FormItem className="col-6">
                  <FormControl>
                    <AnimatedSelect
                      value={
                        field.value
                          ? { value: field.value, label: field.value }
                          : undefined
                      }
                      options={[
                        { label: "Herr", value: "Herr" },
                        { label: "Frau", value: "Frau" },
                      ]}
                      isDisabled={isDateEmpty}
                      placeholder={t(
                        "contactDetails:inputs:salutation:placeholder"
                      )}
                      onChange={(e: any) => {
                        return field.onChange(e?.value);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem className="col-6">
                  <FormControl>
                    <AnimatedInput
                      disabled={isDateEmpty}
                      placeholder={t(
                        "contactDetails:inputs:firstName:placeholder"
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem className="col-6">
                  <FormControl>
                    <AnimatedInput
                      disabled={isDateEmpty}
                      placeholder={t(
                        "contactDetails:inputs:lastName:placeholder"
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="row">
            <FormField
              control={form.control}
              name="companyName"
              render={({ field }) => (
                <FormItem className="col-6">
                  <FormControl>
                    <AnimatedInput
                      disabled={isDateEmpty}
                      placeholder={`${t(
                        "contactDetails:inputs:companyName:placeholder"
                      )} (${t("general:optional")})`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Code disabled={isDateEmpty} setCodeValid={setCodeValid} />
          </div>

          <div className="row">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="col-6">
                  <FormControl>
                    <AnimatedInput
                      disabled={isDateEmpty}
                      type="email"
                      placeholder={t("contactDetails:inputs:email:placeholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="telephoneNumber"
              render={({ field }) => (
                <FormItem className="col-6">
                  <FormControl>
                    <AnimatedInput
                      disabled={isDateEmpty}
                      type="text"
                      placeholder={t(
                        "contactDetails:inputs:telephoneNumber:placeholder"
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="row">
            <FormField
              control={form.control}
              name="contactCityAndZipCode"
              render={({ field }) => (
                <FormItem className="col-12">
                  <FormControl>
                    <AnimatedInput
                      disabled={isDateEmpty}
                      placeholder={t(
                        "contactDetails:inputs:contactCityAndZipCode:placeholder"
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="contactStreetAndHouseNumber"
              render={({ field }) => (
                <FormItem className="col-12">
                  <FormControl>
                    <AnimatedInput
                      disabled={isDateEmpty}
                      placeholder={t(
                        "contactDetails:inputs:contactStreetAndHouseNumber:placeholder"
                      )}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="row">
            <FormField
              control={form.control}
              name="notes"
              render={({ field }) => (
                <FormItem className="col-12">
                  <FormControl>
                    <AnimatedTextarea
                      disabled={isDateEmpty}
                      rows={6}
                      placeholder={t("contactDetails:inputs:notes:placeholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <UploadComponent form={form} />
        </div>
      </div>
    </div>
  );
};

export { ContactDetailsForm };

function Code({
  disabled,
  setCodeValid,
}: {
  disabled: boolean;
  setCodeValid: Function;
}) {
  const { t } = useTranslation();
  const [code, setCode] = useState("");

  const { setDiscountPercentage } = useDiscountStore();
  const { setValue } = useFormContext<FormSchema>();

  const requestCustomerFn = useAsyncFn<{ data: CustomerResponse }>(
    requestCustomer
  );

  const onCodeChange = async (value: string) => {
    setCode(value);
    if (!value.trim()) {
      return;
    }
    const data = await requestCustomerFn.execute({ code: value });
    setDiscountPercentage(Number(data.data.discount));
    setCodeValid(true);

    setValue("salutation", data.data.salutation, { shouldValidate: true });
    setValue("firstName", data.data.firstName, { shouldValidate: true });
    setValue("lastName", data.data.lastName, { shouldValidate: true });
    setValue("companyName", data.data.companyName, { shouldValidate: true });
    setValue("email", data.data.email, { shouldValidate: true });
    setValue("telephoneNumber", data.data.telephoneNumber, {
      shouldValidate: true,
    });
    setValue("contactStreetAndHouseNumber", data.data.streetAndHouseNumber, {
      shouldValidate: true,
    });
    setValue("contactCityAndZipCode", data.data.cityAndZipCode, {
      shouldValidate: true,
    });
  };

  return (
    <div className="col-6 code-container">
      <DebounceControl
        value={code}
        delay={500}
        onDebouncedChange={onCodeChange}
        render={({ onChange }) => (
          <AnimatedInput
            disabled={disabled}
            placeholder={`${t("contactDetails:inputs:code:placeholder")} (${t(
              "general:optional"
            )})`}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      />

      {requestCustomerFn.loading && <InputLoading />}
    </div>
  );
}
