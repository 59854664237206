import React from "react";

const Textarea = React.forwardRef<
  HTMLTextAreaElement,
  React.ComponentProps<"textarea">
>(({ className, rows, ...props }, ref) => {
  return <textarea className={className} rows={rows} ref={ref} {...props} />;
});
Textarea.displayName = "Textarea";

export { Textarea };
