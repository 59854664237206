export const baseURL = process.env.REACT_APP_BASEURL as string;
export const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID as string;

// export const permissionForElevatorPrice = 89;
export const extraDayPrice = 15;
export const extraDayPriceWithTwoSiteBlock = 25;
// export const PAYPAL_PERCENTAGE_FEE = 2.99; // 2.99 %
// export const PAYPAL_FIXED_INCREMENT = 0.39;
export const TWO_SIDE_BLOCKING_PRICE = 49;

export const FALLBACK_BASE_PRICE = 100;
export const FALLBACK_PERMISSION_PRICE = 25;
export const FALLBACK_OUTSIDE_LIFT_PRICE = 28;

export const MAX_UPLOAD_FILE_SIZE = 10; // 10 MB
export const MAX_NO_OF_FILES_AT_ONCE = 10;

export const calendarTheme = {
  primary: {
    light: "#757ce8",
    main: "#16419c",
    dark: "#002884",
  },
  grey: {
    700: "#707070",
    900: "#1b1b1d",
  },
  background: {
    default: "#f5f5f5",
  },
  text: {
    disabled: "#BABABA",
  },
};

export const PURPOSE_OPTIONS = {
  umzug: { value: "umzug", label: "Umzug" },
  lieferung: { value: "lieferung", label: "Lieferung" },
  baustelle: { value: "baustelle", label: "Baustelle" },
  "film&events": { value: "film&events", label: "Film & Events" },
  sonstiges: { value: "sonstiges", label: "Sonstiges" },
} as const;

export const purposeOptions = Object.values(PURPOSE_OPTIONS);

export const numberOfDaysSelection = () => {
  let response = [];
  for (let i = 0; i < 100; i++) {
    response[i] = {
      value: (i + 1).toString(),
      label: (i + 1).toString(),
    };
  }
  return response;
};

export const germanDaysOfWeek = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

export const nextRequiredMessage = (labelName: string) =>
  `Bitte füllen Sie "${labelName}" aus! "${labelName}"`;

export const LENGTH_OF_BOARD = {
  "10m": "10 Meter",
  "15m": "15 Meter",
  "20m": "20 Meter",
  "25m": "25 Meter",
} as const;

export const lengthsOfBoard = Object.values(LENGTH_OF_BOARD);
