import classNames from "classnames";
import { FC, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useGlobalStore, useInstallationStore } from "src/store";
import { PurposeKey } from "src/types";
import {
  LENGTH_OF_BOARD,
  lengthsOfBoard,
  PURPOSE_OPTIONS,
  purposeOptions,
} from "src/utils/constants";
import { FormSchema } from "src/utils/formSchema";
import { getAllTime } from "src/utils/helpers";
import ReactSelect from "./common/react-select";
import {
  CitySelector,
  DateSelector,
  FormTitle,
  NumberOfDaysSelector,
} from "./form";
import SwitchComponent from "./SwitchComponent/SwitchComponent";

export type RangePickerSelectedDays = {
  from: string;
  to: string;
};

type Props = {
  form: UseFormReturn<FormSchema, unknown>;
  codeValid: boolean;
  currentStep: number;
};

const DateRange: FC<Props> = ({ form, codeValid, currentStep }) => {
  const { installationDetails, setInstallationDetails } =
    useInstallationStore();

  const { global } = useGlobalStore();
  const { t } = useTranslation();

  const handlePermissionChange = (checked: boolean) => {
    setInstallationDetails({ withPermission: !checked });
  };

  const handleTwoSiteBlockChange = (checked: boolean) => {
    setInstallationDetails({ twoSiteBlock: checked });
  };

  const handleWithOutsideLiftChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => {
    e &&
      setInstallationDetails({
        withOutsideLift: value,
      });
  };

  const endOfTimeFrom = Number(installationDetails.timeFrom.substring(0, 2));
  const startOfTimeTill = endOfTimeFrom < 12 ? 12 : endOfTimeFrom + 1; // timeFrom should be less than timeTill and default to 12

  const isDateEmpty = !installationDetails.dateRange.from;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div>
      <div className="border">
        <FormTitle />
        <CitySelector form={form} />
        <div className="row">
          <div className="col-12">
            <div className="floating-input">
              <input
                className="form-control"
                {...form.register("zipCode")}
                type="number"
                id="zipCode"
                autoFocus
                placeholder=" "
                value={installationDetails.zipCode}
                disabled={isDateEmpty}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInstallationDetails({
                    zipCode: e.target.value,
                  });
                }}
                aria-invalid={
                  !!form.formState.errors?.zipCode?.message &&
                  global.showRequired
                }
              />
              <label htmlFor="zipCode">
                {t("installations:inputs:zipCode:placeholder")}
              </label>
              <fieldset className="fieldset">
                <legend>{t("installations:inputs:zipCode:placeholder")}</legend>
              </fieldset>
            </div>
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {form.formState.errors?.zipCode?.message}
            </p>
          </div>

          <div className="col-12">
            <div className="floating-input">
              <input
                className="form-control"
                {...form.register("streetAndHouseNumber")}
                type="text"
                id="streetAndHouseNumber"
                placeholder=" "
                value={installationDetails.streetAndHouseNumber}
                disabled={isDateEmpty}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInstallationDetails({
                    streetAndHouseNumber: e.target.value,
                  });
                }}
                aria-invalid={
                  !!form.formState.errors?.streetAndHouseNumber?.message &&
                  global.showRequired
                }
              />
              <label htmlFor="streetAndHouseNumber">
                {t("installations:inputs:streetAndHouseNumber:placeholder")}
              </label>
              <fieldset className="fieldset">
                <legend>
                  {t("installations:inputs:streetAndHouseNumber:placeholder")}
                </legend>
              </fieldset>
            </div>
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {form.formState.errors?.streetAndHouseNumber?.message}
            </p>
          </div>
        </div>

        <div className="row row-no-of-days">
          <div className="col-6">
            <DateSelector currentStep={currentStep} />
          </div>
          <div className="col-6">
            <NumberOfDaysSelector currentStep={2} />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div
              className={classNames("floating-select", {
                focused: isFocused || !!installationDetails.timeFrom,
              })}
            >
              <Controller
                control={form.control}
                name="timeFrom"
                defaultValue="07:00"
                render={({ field: { ref, onChange, value } }) => (
                  <ReactSelect
                    ref={ref}
                    id="timeFrom"
                    placeholder=" "
                    options={getAllTime(6, 16).map((time) => ({
                      label: time,
                      value: time,
                    }))}
                    isDisabled={isDateEmpty}
                    value={{ label: value, value }}
                    onFocus={handleFocus}
                    onBlur={() => {
                      handleBlur();
                    }}
                    onChange={(e: any) => {
                      setInstallationDetails({ timeFrom: e ? e.value : "" });
                      return onChange(e?.value);
                    }}
                    classNamePrefix="select"
                    aria-invalid={
                      !!form.formState.errors.timeFrom?.message &&
                      global.showRequired
                    }
                  />
                )}
              />
              <label htmlFor="timeFrom" className="select-label">
                {t("contactDetails:inputs:timeFrom:placeholder")}
              </label>
              <fieldset className="fieldset">
                <legend>
                  {t("contactDetails:inputs:timeFrom:placeholder")}
                </legend>
              </fieldset>
            </div>

            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {form.formState.errors.timeFrom?.message}
            </p>
          </div>
          <div className="col-6">
            <div
              className={classNames("floating-select", {
                focused: isFocused || !!installationDetails.timeTill,
              })}
            >
              <Controller
                control={form.control}
                name="timeTill"
                defaultValue="18:00"
                render={({ field: { ref, onChange, value } }) => (
                  <ReactSelect
                    ref={ref}
                    id="timeTill"
                    placeholder=" "
                    options={getAllTime(startOfTimeTill, 22).map((time) => ({
                      label: time,
                      value: time,
                    }))}
                    isDisabled={isDateEmpty}
                    onFocus={handleFocus}
                    onBlur={() => {
                      handleBlur();
                    }}
                    value={{ label: value, value }}
                    onChange={(e: any) => {
                      setInstallationDetails({ timeTill: e ? e.value : "" });
                      onChange(e?.value);
                    }}
                    classNamePrefix="select"
                    aria-invalid={
                      !!form.formState.errors.timeTill?.message &&
                      global.showRequired
                    }
                  />
                )}
              />
              <label htmlFor="timeTill" className="select-label">
                {t("contactDetails:inputs:timeTill:placeholder")}
              </label>
              <fieldset className="fieldset">
                <legend>
                  {t("contactDetails:inputs:timeTill:placeholder")}
                </legend>
              </fieldset>
            </div>
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {form.formState.errors.timeTill?.message}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div
              className={classNames("floating-select", {
                focused: isFocused || !!installationDetails.purpose,
              })}
            >
              <Controller
                control={form.control}
                name="purpose"
                defaultValue={PURPOSE_OPTIONS["umzug"].value}
                render={({ field: { ref, onChange, value } }) => (
                  <ReactSelect
                    ref={ref}
                    id="purpose"
                    placeholder=" "
                    options={purposeOptions}
                    isDisabled={isDateEmpty}
                    value={PURPOSE_OPTIONS[value as PurposeKey]}
                    onChange={(e: any) => {
                      setInstallationDetails({ purpose: e ? e.value : "" });
                      onChange(e?.value);
                    }}
                    onFocus={handleFocus}
                    onBlur={() => {
                      handleBlur();
                    }}
                    classNamePrefix="select"
                    aria-invalid={
                      !!form.formState.errors.purpose?.message &&
                      global.showRequired
                    }
                  />
                )}
              />
              <label htmlFor="purpose" className=" select-label">
                {t("installations:inputs:purpose:placeholder")}
              </label>
              <fieldset className="fieldset">
                <legend>{t("installations:inputs:purpose:placeholder")}</legend>
              </fieldset>
            </div>
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {form.formState.errors.purpose?.message}
            </p>
          </div>

          <div className="col-12">
            <div
              className={classNames("floating-select", {
                focused: isFocused || !!installationDetails.lengthOfBoard,
              })}
            >
              <Controller
                control={form.control}
                name="lengthOfBoard"
                defaultValue={LENGTH_OF_BOARD["15m"]}
                render={({ field: { ref, onChange, value } }) => (
                  <ReactSelect
                    ref={ref}
                    id="lengthOfBoard"
                    placeholder=" "
                    options={lengthsOfBoard.map((len) => ({
                      label: len,
                      value: len,
                    }))}
                    isDisabled={isDateEmpty}
                    value={{ label: value, value }}
                    onChange={(e: any) => {
                      setInstallationDetails({
                        lengthOfBoard: e ? e.value : "",
                      });
                      onChange(e?.value);
                    }}
                    onFocus={handleFocus}
                    onBlur={() => {
                      handleBlur();
                    }}
                    classNamePrefix="select"
                    aria-invalid={
                      !!form.formState.errors.lengthOfBoard?.message &&
                      global.showRequired
                    }
                  />
                )}
              />
              <label htmlFor="lengthOfBoard" className="select-label">
                {t("installations:inputs:lengthOfBoard:placeholder")}
              </label>
              <fieldset className="fieldset">
                <legend>
                  {t("installations:inputs:lengthOfBoard:placeholder")}
                </legend>
              </fieldset>
            </div>
            <p
              className={classNames({
                required: true,
                "display-none": !global.showRequired,
              })}
            >
              {form.formState.errors.lengthOfBoard?.message}
            </p>
          </div>
        </div>

        <SwitchComponent
          id="permission-switch"
          label={t("installations:checkboxes:withPermission:label")}
          tooltipLabel={t(
            "installations:checkboxes:withPermission:tooltipLabel"
          )}
          tooltipContent={t(
            "installations:checkboxes:withPermission:tooltipContent"
          )}
          description={t(
            "installations:checkboxes:withPermission:orderIncludingAuthorisation"
          )}
          checked={!installationDetails.withPermission}
          onChange={handlePermissionChange}
          disabled={isDateEmpty}
        />

        <SwitchComponent
          id="twoSiteBlock-switch"
          label={t("installations:checkboxes:twoSiteBlock:label")}
          tooltipLabel={t("installations:checkboxes:twoSiteBlock:tooltipLabel")}
          tooltipContent={t(
            "installations:checkboxes:twoSiteBlock:tooltipContent"
          )}
          description={t("installations:checkboxes:twoSiteBlock:bothSided")}
          checked={installationDetails.twoSiteBlock}
          onChange={handleTwoSiteBlockChange}
          disabled={isDateEmpty}
        />

        {codeValid && (
          <div className="radio-wrapper">
            <label className="input-label">
              {t("installations:checkboxes:withOutsideLift:label")}
            </label>

            <div className="radio-container">
              <label
                className="radio-input-label"
                htmlFor="withOutsideLift_false"
              >
                <input
                  type="radio"
                  id="withOutsideLift_false"
                  name="withOutsideLift"
                  value="false"
                  className="radio-input"
                  onChange={(e) => handleWithOutsideLiftChange(e, false)}
                  checked={!installationDetails.withOutsideLift}
                  disabled={isDateEmpty}
                />
                {t("installations:checkboxes:withOutsideLift:false")}
              </label>

              <label
                className="radio-input-label"
                htmlFor="withOutsideLift_true"
              >
                <input
                  type="radio"
                  id="withOutsideLift_true"
                  name="withOutsideLift"
                  value="true"
                  className="radio-input"
                  onChange={(e) => handleWithOutsideLiftChange(e, true)}
                  checked={installationDetails.withOutsideLift}
                  disabled={isDateEmpty}
                />
                {t("installations:checkboxes:withOutsideLift:true")}
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { DateRange };
