import React from "react";
import Select, { ControlProps, GroupBase } from "react-select";

const getBorderColor = (
  state: ControlProps<unknown, boolean, GroupBase<unknown>>,
  props: React.ComponentProps<typeof Select>
) => {
  if (state.isFocused) {
    return "none";
  }
  if (props["aria-invalid"]) {
    return "1px solid red";
  }
  if (props.value) {
    return "transparent";
  }
  return "1px solid #ccc";
};

const ReactSelect = React.forwardRef<
  React.ComponentRef<typeof Select>,
  React.ComponentPropsWithoutRef<typeof Select>
>(({ className, ...props }, ref) => (
  <Select
    ref={ref}
    styles={{
      control: (base, state) => ({
        ...base,
        minHeight: "2.6rem",
        backgroundColor: "transparent",
        borderRadius: "4px",
        opacity: state.isDisabled ? 0.7 : 1,
        border: getBorderColor(state, props),
        borderColor: "transparent",
        boxShadow: "none",
        transition: "none",
        "&:hover": {
          borderColor: "none",
        },
      }),
    }}
    {...props}
  />
));

ReactSelect.displayName = "ReactSelect";

export default ReactSelect;
