// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form_form-message__N3qo6 {\n  font-size: 10px;\n  color: red;\n  margin: 4px 2px;\n  height: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ui/form.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,eAAe;EACf,YAAY;AACd","sourcesContent":[".form-message {\n  font-size: 10px;\n  color: red;\n  margin: 4px 2px;\n  height: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-message": "form_form-message__N3qo6"
};
export default ___CSS_LOADER_EXPORT___;
