import i18n from "src/i18n";
import * as yup from "yup";

let required = i18n.t("warnings:required");
let notAnEmail = i18n.t("warnings:notAnEmail");

export const formSchema = yup.object({
  email: yup.string().email(notAnEmail).required(required),
  contactStreetAndHouseNumber: yup.string().required(required),
  telephoneNumber: yup.string().required(required),
  contactCityAndZipCode: yup.string().required(required),
  firstName: yup.string().required(required),
  salutation: yup.string().required(required),
  lastName: yup.string().required(required),
  companyName: yup.string(),
  notes: yup.string(),

  streetAndHouseNumber: yup.string().required(required),
  zipCode: yup.string().required(required),
  city: yup.array().required(required),
  purpose: yup.string().required(required),
  timeFrom: yup.string().required(required),
  timeTill: yup.string().required(required),
  lengthOfBoard: yup.string().required(required),
  files: yup.mixed().optional(),
});

export type FormSchema = yup.InferType<typeof formSchema>;
