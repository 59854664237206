import { yupResolver } from "@hookform/resolvers/yup";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ContactDetailsForm,
  DateRange,
  PaypalCheckoutButton,
  StepOneForm,
} from "src/components";
import { paypalClientId } from "src/utils/constants";
import { Form } from "./components/ui/form";
import { useGlobalStore } from "./store";
import { FormSteps } from "./types";
import { formSchema, FormSchema } from "./utils/formSchema";
import { PARENT_WORDPRESS_URL } from "./utils/urls";

function App() {
  const form = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      firstName: "",
      salutation: "",
      lastName: "",
      companyName: "",
      email: "",
      telephoneNumber: "",
      contactStreetAndHouseNumber: "",
      contactCityAndZipCode: "",
      notes: "",
    },
  });

  const [currentStep, setCurrentStep] = useState<FormSteps>(1);
  const { global } = useGlobalStore();
  const [codeValid, setCodeValid] = useState(false);

  useEffect(() => {
    /**
     * To change height of iframe dynamically according to height of form
     */
    const scrollHeight = document.documentElement.scrollHeight;
    // window.parent.postMessage({ scrollHeight }, LOCAL_PARENT_URL); // uncomment this for testing
    window.parent.postMessage({ scrollHeight }, PARENT_WORDPRESS_URL);
  }, [currentStep]);

  return (
    <PayPalScriptProvider
      options={{
        "client-id": paypalClientId,
        currency: "EUR",
        components: "buttons",
      }}
    >
      <Form {...form}>
        {currentStep === 1 && (
          <StepOneForm setCurrentStep={setCurrentStep} form={form} />
        )}
        {currentStep === 2 && (
          <>
            <div
              className="wrapper"
              style={
                global.isSubmitting ? { pointerEvents: "none" } : undefined
              }
            >
              <DateRange
                form={form}
                codeValid={codeValid}
                currentStep={currentStep}
              />
              <ContactDetailsForm form={form} setCodeValid={setCodeValid} />
            </div>
            <PaypalCheckoutButton form={form} />
          </>
        )}
      </Form>
      <ToastContainer />
    </PayPalScriptProvider>
  );
}

export default App;
