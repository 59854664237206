import { useEffect, useRef, useState } from "react";
import {
  Accept,
  ErrorCode,
  FileError,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  MAX_NO_OF_FILES_AT_ONCE,
  MAX_UPLOAD_FILE_SIZE,
} from "src/utils/constants";
import { FormSchema } from "src/utils/formSchema";

const defaultAcceptedFileType = {
  "image/*": [".png", ".jpeg", ".heic"],
  "application/pdf": [".pdf"],
};

type Props = {
  name: "files"; // Not the most resuable way to do this, but fixing ts for now
  onChange: Function;
  maxSize?: number;
  maxFiles?: number;
  acceptedFileType?: Accept;
  isLoading?: boolean;
  clearFileInputToggle?: boolean; // Clear files on toggle of this prop
  form: UseFormReturn<FormSchema, unknown>;
  disabled: boolean;
};

export function FileUploader({
  name,
  onChange,
  acceptedFileType = defaultAcceptedFileType,
  maxSize = MAX_UPLOAD_FILE_SIZE,
  maxFiles = MAX_NO_OF_FILES_AT_ONCE,
  clearFileInputToggle,
  form,
  disabled,
}: Props) {
  const [files, setFiles] = useState<File[]>([]);
  const isFirstMount = useRef(true);

  const { t } = useTranslation();

  const fileSizeValidator = (file: File): FileError | FileError[] | null => {
    const fileSize = file.size / 1024 / 1024; // Size in MB
    if (fileSize > maxSize) {
      return {
        code: ErrorCode.FileTooLarge,
        message: t("upload:errors.fileTooLarge", { fileName: file.name }),
      };
    }
    return null;
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const hasManyFilesError = fileRejections.some((e) => {
      return e.errors.some((error) => {
        return error.code === ErrorCode.TooManyFiles;
      });
    });

    if (hasManyFilesError) {
      form.setError(name, {
        message: t("upload:errors.tooManyFiles"),
      });
      return;
    }

    if (fileRejections.length > 0) {
      fileRejections.forEach((e) => {
        e.errors.forEach((error) => {
          if (error.code === ErrorCode.FileInvalidType) {
            form.setError(name, {
              message: t("upload:errors.invalidFileType"),
            });
          } else {
            form.setError(name, error);
          }
        });
      });
      return;
    }

    const newFiles = [...files, ...acceptedFiles];
    if (newFiles.length > maxFiles) {
      form.setError(name, {
        message: t("upload:errors.tooManyFiles"),
      });
      return;
    }
    setFiles(newFiles);
  };

  useEffect(() => {
    onChange(files);
  }, [files]);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
    } else {
      setFiles([]);
    }
  }, [clearFileInputToggle]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileType,
    validator: fileSizeValidator,
    onDrop,
    maxFiles,
  });

  const removeFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <div style={disabled ? { pointerEvents: "none" } : undefined}>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} disabled={disabled} />
        <div>{t("upload:dragDropMsg", { maxFiles, maxSize })}</div>
        <div style={{ fontSize: "2.6rem", lineHeight: "1" }}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 1024 1024"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M480 580H372a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h108v108a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8V644h108a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8H544V472a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v108zm374.6-291.3c6 6 9.4 14.1 9.4 22.6V928c0 17.7-14.3 32-32 32H192c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32h424.7c8.5 0 16.7 3.4 22.7 9.4l215.2 215.3zM790.2 326L602 137.8V326h188.2z"></path>
          </svg>
        </div>
      </div>

      <div className="file-list">
        {files?.map((file, index) => (
          <div key={index}>
            <div className="file">
              <p>{file.name}</p>
              <button type="button" onClick={() => removeFile(index)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
