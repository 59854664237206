import React from "react";

import classNames from "classnames";

import { Input } from "../ui/input";
import { Textarea } from "../ui/textarea";
import styles from "./animated-input.module.css";

const AnimatedInput = React.forwardRef<
  React.ComponentRef<typeof Input>,
  React.ComponentProps<typeof Input>
>(({ className, placeholder, type, id, ...props }, ref) => {
  const fallbackId = React.useId();
  const finalId = id ?? fallbackId;
  return (
    <div className={styles["floating-input"]}>
      <Input
        ref={ref}
        id={finalId}
        placeholder=""
        className={classNames(styles.input, className)}
        {...props}
      />
      <label htmlFor={finalId}>{placeholder}</label>
      <fieldset className={styles.fieldset}>
        <legend>{placeholder}</legend>
      </fieldset>
    </div>
  );
});
AnimatedInput.displayName = "AnimatedInput";

const AnimatedTextarea = React.forwardRef<
  React.ComponentRef<typeof Textarea>,
  React.ComponentProps<typeof Textarea>
>(({ className, placeholder, rows, id, ...props }, ref) => {
  const fallbackId = React.useId();
  const finalId = id ?? fallbackId;
  return (
    <div className={styles["floating-input"]}>
      <Textarea
        ref={ref}
        id={finalId}
        placeholder=""
        className={classNames(styles.input, className)}
        rows={rows}
        {...props}
      />
      <label htmlFor={finalId}>{placeholder}</label>
      <fieldset className={styles.fieldset}>
        <legend>{placeholder}</legend>
      </fieldset>
    </div>
  );
});
AnimatedTextarea.displayName = "AnimatedTextarea";

export { AnimatedInput, AnimatedTextarea };
