import * as Switch from "@radix-ui/react-switch";
import * as Tooltip from "@radix-ui/react-tooltip";
import styles from "./SwitchComponent.module.css";

interface SwitchComponentProps {
  id: string;
  label: string;
  tooltipLabel: string;
  tooltipContent: string;
  description: string;
  checked: boolean;
  disabled: boolean;
  onChange: (checked: boolean) => void;
}

const SwitchComponent: React.FC<SwitchComponentProps> = ({
  id,
  label,
  tooltipLabel,
  tooltipContent,
  description,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <div
      className={styles.switchContainer}
      onClick={() => {
        if (!disabled) {
          onChange(!checked);
        }
      }}
    >
      <div className={styles.switchLabel}>
        <span className={styles.title}>
          {label}
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <button className={styles.infoIcon}>ℹ</button>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content className={styles.tooltipContent}>
                  <p>
                    <strong>{tooltipLabel}</strong>
                  </p>
                  <p>{tooltipContent}</p>
                  <Tooltip.Arrow className={styles.tooltipArrow} />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </span>
        <span className={styles.description}>{description}</span>
      </div>
      <Switch.Root
        id={id}
        className={styles.switchRoot}
        checked={checked}
        onCheckedChange={onChange}
        disabled={disabled}
      >
        <Switch.Thumb className={styles.switchThumb} />
      </Switch.Root>
    </div>
  );
};

export default SwitchComponent;
