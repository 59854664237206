import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RangePicker } from "react-trip-date";
import { useWindowDimensions } from "src/hooks";
import { useInstallationStore, useNumberOfDaysStore } from "src/store";
import { calendarTheme, germanDaysOfWeek } from "src/utils/constants";
import {
  blockDatesAccordingToDay,
  germanDateFormat,
  getDaysBetweenDates,
  getNextYearFromNow,
} from "src/utils/helpers";

export type RangePickerSelectedDays = {
  from: string;
  to: string;
};

type Props = {
  isStepOne?: boolean;
  currentStep?: number;
};

export default function DateSelector({
  isStepOne = false,
  currentStep,
}: Props) {
  const { installationDetails, setInstallationDetails } =
    useInstallationStore();

  const { resetNumberOfDays } = useNumberOfDaysStore();
  const { t } = useTranslation();

  const isCitySelected = !!installationDetails.city;
  const initialShowPicker = isStepOne && isCitySelected;
  const [showPicker, setShowPicker] = useState(initialShowPicker);
  const [isFocused, setIsFocused] = useState(false);

  const calendarRef = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        document.contains(e.target) &&
        !calendarRef.current?.contains(e.target)
      ) {
        setShowPicker(false);
        setIsFocused(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, []);

  const handleFocus = () => setIsFocused(true);

  const rangePickerChange = (dates: RangePickerSelectedDays) => {
    if (dates.from === "") {
      setInstallationDetails({
        withPermission: false,
        twoSiteBlock: false,
      });
    }
    if (dates.from) {
      setShowPicker(false);
      setInstallationDetails({
        dateRange: {
          from: dates.from,
          to: dates.to || dates.from,
        },
      });
      resetNumberOfDays();
    }
  };

  const getDateRangeValue = () => {
    if (installationDetails.dateRange.from) {
      return `${installationDetails.dateRange.from}`;
    }
    return undefined;
  };

  const upcomingBlockedDates = blockDatesAccordingToDay();

  const dateRange = getDateRangeValue();

  const numberOfMonths = width < 1000 ? 1 : 3;

  return (
    <div
      ref={calendarRef}
      className="picker-wrapper"
      style={!isCitySelected ? { pointerEvents: "none" } : {}}
    >
      <div
        className={classNames("floating-select", {
          focused: isFocused || !!dateRange,
        })}
      >
        <div
          className={classNames("picker-toggle date-selector-form", {
            "error-border": dateRange === undefined && currentStep === 2,
          })}
        >
          <button
            id="showPicker"
            className={classNames("showPicker", {
              focused: isFocused || !!dateRange,
            })}
            disabled={!isCitySelected}
            onClick={() => {
              setShowPicker((showPicker) => !showPicker);
              handleFocus();
            }}
          >
            {dateRange && germanDateFormat(dateRange)}
          </button>
          <img
            src={
              showPicker
                ? "/assets/multiply-80.png"
                : "https://i.ibb.co/MGwM6Ck/calendar.png"
            }
            alt="calendar"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowPicker((showPicker) => !showPicker);
              handleFocus();
            }}
          />
        </div>
        {showPicker && (
          <div className={`${isStepOne ? "calendar-center" : ""}`}>
            <RangePicker
              numberOfMonths={numberOfMonths}
              startOfWeek={1}
              theme={calendarTheme}
              onChange={rangePickerChange}
              disabledBeforeToday
              disabledDays={[
                ...getDaysBetweenDates(new Date(), getNextYearFromNow(), "Sun"),
                ...upcomingBlockedDates,
              ]}
              autoResponsive={false}
              components={{
                days: ({ day }) => (
                  <div>
                    <b
                      style={{
                        lineHeight: "2rem",
                      }}
                    >
                      {day.split("-")[2]}
                    </b>
                  </div>
                ),
                header: {
                  format: "MMM YYYY",
                },
                titleOfWeek: {
                  titles: germanDaysOfWeek,
                },
              }}
            />
          </div>
        )}
        <label htmlFor="date" className="input-label  select-label">
          {t("installations:inputs:dateRange:placeholder")}
        </label>
        <fieldset className="fieldset">
          <legend>{t("installations:inputs:dateRange:placeholder")}</legend>
        </fieldset>
      </div>
      <p className="display-none"></p>
    </div>
  );
}
