import { PayPalButtons } from "@paypal/react-paypal-js";
import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useAsyncFn } from "src/hooks";
import { usePriceCalculation } from "src/hooks/usePriceCalculation";
import { requestPayment } from "src/services";
import { uploadFiles } from "src/services/payment";
import { useGlobalStore, useInstallationStore } from "src/store";
import { useDiscountStore } from "src/store/discount";
import { useFilesStore } from "src/store/files";
import { extraDayPrice } from "src/utils/constants";
import { FormSchema } from "src/utils/formSchema";
import {
  formattedAmount,
  germanDateFormat,
  showToast,
} from "src/utils/helpers";
import { PARENT_WORDPRESS_URL, TERMS_AND_CONDITION_URL } from "src/utils/urls";

export type PriceTableSchema = {
  id: number;
  article: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  enabled: boolean;
  netPrice?: number;
};

type PaypalCheckoutButtonProps = {
  form: UseFormReturn<FormSchema, unknown>;
};

const PaypalCheckoutButton: FC<PaypalCheckoutButtonProps> = ({ form }) => {
  const { global, setIsSubmitting, changeShowRequired, setSubmitType } =
    useGlobalStore();
  const { t } = useTranslation();
  const requestPaymentFn = useAsyncFn(requestPayment);
  const uploadFilesFn = useAsyncFn(uploadFiles);
  const { installationDetails } = useInstallationStore();
  const [paymentRadio, setPaymentRadio] = useState("booked");
  const [paypalCorrelationID, setPaypalCorrelationID] = useState("");
  const [isTermsAndConditionChecked, setIsTermsAndConditionChecked] =
    useState(false);
  const { files } = useFilesStore();
  const { discountPercentage } = useDiscountStore();
  const {
    totalBasePrice,
    extraDays,
    totalExtraDaysPrice,
    totalTwoSideBlockingPrice,
    totalPermissionPrice,
    totalWithOutsideLiftPrice,
    discountAmount,
    grandTotal,
  } = usePriceCalculation();

  const handlePaypalButtonInit = (initData: any) => {
    if (
      initData &&
      initData.correlationID &&
      initData.correlationID !== paypalCorrelationID
    ) {
      setPaypalCorrelationID(initData.correlationID);
    }
  };

  useEffect(() => {
    const scrollHeight = document.documentElement.scrollHeight;
    // window.parent.postMessage({ scrollHeight }, LOCAL_PARENT_URL); // uncomment this for testing
    window.parent.postMessage({ scrollHeight }, PARENT_WORDPRESS_URL);
  }, [global.showRequired]);

  const handleApprove = async (order: any, type?: string) => {
    try {
      setIsSubmitting(true);
      const {
        salutation,
        firstName,
        lastName,
        companyName,
        email,
        telephoneNumber,
        contactStreetAndHouseNumber,
        contactCityAndZipCode,
        notes,
      } = form.getValues();

      // Call backend function to fulfill order
      const resp = await requestPaymentFn.execute({
        order,
        userDetails: {
          firstName,
          salutation,
          lastName,
          companyName,
          email,
          telephoneNumber,
          contactStreetAndHouseNumber,
          contactCityAndZipCode,
          notes,
        },
        installationDetails,
        type,
        priceTableInformation,
      });

      if (files.length > 0) {
        await uploadFilesFn.execute(resp.data.id, files);
      }

      showToast("paymentSuccess");
      setIsSubmitting(false);
      setTimeout(() => {
        if (window.top) {
          window.top.location.replace(
            `${process.env.REACT_APP_WORDPRESS}/success?orderNumber=${resp.data.paymentId}`
          );
        }
      }, 1000);
    } catch (e) {
      showToast("paymentError", "error");
      setIsSubmitting(false);
    }
  };

  const checkValidation = () => {
    if (
      grandTotal !== 0 &&
      Object.keys(form.formState.errors).length === 0 &&
      isTermsAndConditionChecked
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handlePaypalSubmit = () => {
    form.handleSubmit(() => {})();
    changeShowRequired();
  };

  const handleRadioChange = (value: string) => {
    if (value !== "pay_paypal") {
      setPaypalCorrelationID("");
    }
    setSubmitType(value);
    setPaymentRadio(value);
  };

  const currentCityLabelWithoutLowestPrice =
    +grandTotal &&
    global.cities.find((city) => city.value === installationDetails.city?.id)
      ?.labelWithoutLowestPrice;

  const priceTableInformation: PriceTableSchema[] = [
    {
      id: 1,
      article:
        "Halterverbotszone, " +
        currentCityLabelWithoutLowestPrice +
        ", Deutschland (1 Tag)",
      quantity: 1,
      unitPrice: totalBasePrice,
      totalPrice: totalBasePrice,
      enabled: true,
    },
    {
      id: 2,
      article: t("charges:closureForFewDays"),
      quantity: extraDays,
      unitPrice: extraDayPrice,
      totalPrice: totalExtraDaysPrice,
      enabled: !!extraDays,
    },
    {
      id: 3,
      article: t("charges:twoSiteBlock"),
      quantity: 1,
      unitPrice: totalTwoSideBlockingPrice,
      totalPrice: totalTwoSideBlockingPrice,
      enabled: installationDetails.twoSiteBlock,
    },
    {
      id: 4,
      article: t("charges:withOutsideLift"),
      quantity: 1,
      unitPrice: totalWithOutsideLiftPrice,
      totalPrice: totalWithOutsideLiftPrice,
      enabled: installationDetails.withOutsideLift,
    },
    {
      id: 5,
      article: `${t("charges:discount")} (${discountPercentage}%)`,
      quantity: 0,
      unitPrice: 0,
      totalPrice: -discountAmount,
      enabled: !!discountAmount,
    },
    {
      id: 6,
      article: t("charges:withPermission"),
      quantity: 1,
      unitPrice: totalPermissionPrice,
      totalPrice: totalPermissionPrice,
      enabled: installationDetails.withPermission,
    },
    {
      id: 8,
      article: `${t("charges:total")} ${t("charges:include")} ${t(
        "charges:mwst"
      )} (${(grandTotal - grandTotal / 1.19).toFixed(2)}€)`,
      quantity: 0,
      unitPrice: 0,
      totalPrice: grandTotal,
      netPrice: formattedAmount(grandTotal / 1.19),
      enabled: true,
    },
  ];

  const isDateEmpty = !installationDetails.dateRange.from;

  return (
    <>
      <div className="table-container">
        <table id="invoice" align="left">
          <thead>
            <tr>
              <th>{t("charges:items")}</th>
              <th>{t("charges:numberOfDays")}</th>
              <th className="break-line">{t("charges:basePrice")}</th>
              <th className="break-line">{t("charges:total")}</th>
            </tr>
          </thead>
          <tbody>
            {priceTableInformation.map((priceRow, index) => {
              const {
                id,
                article,
                enabled,
                quantity,
                totalPrice,
                unitPrice,
                netPrice,
              } = priceRow;
              return enabled ? (
                <tr key={id}>
                  <td data-label={t("charges:items")}>
                    <p>{article}</p>
                    {index === 0 && (
                      <>
                        <span className="street">
                          {installationDetails.streetAndHouseNumber}
                        </span>
                        {installationDetails.dateRange.from &&
                        installationDetails.dateRange.to ? (
                          <>
                            <span className="date-range">
                              {t("charges:from")}:&nbsp;
                              {germanDateFormat(
                                installationDetails.dateRange.from
                              )}
                              &nbsp;
                              {t("charges:to")}:&nbsp;
                              {germanDateFormat(
                                installationDetails.dateRange.to
                              )}
                            </span>
                          </>
                        ) : null}
                      </>
                    )}
                  </td>
                  <td data-label={t("charges:numberOfDays")}>
                    <strong>{quantity ? quantity : "-"}</strong>
                  </td>
                  <td data-label={t("charges:basePrice")}>
                    {unitPrice ? `${unitPrice.toFixed(2)}€` : "-"}
                  </td>
                  <td data-label={t("charges:total")}>
                    {netPrice ? (
                      <>
                        <p style={{ fontSize: "18px", fontWeight: "800" }}>
                          {totalPrice.toFixed(2)}€
                        </p>
                        ({netPrice}€ netto)
                      </>
                    ) : (
                      `${totalPrice.toFixed(2)}€`
                    )}
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      </div>
      <div>
        <label>
          <input
            id="terms"
            type="checkbox"
            checked={isTermsAndConditionChecked}
            disabled={isDateEmpty}
            onChange={() => setIsTermsAndConditionChecked((prev) => !prev)}
            className={classNames({
              "error-outline":
                !isTermsAndConditionChecked && global.showRequired,
            })}
          />

          <span
            className={classNames({
              "error-text": !isTermsAndConditionChecked && global.showRequired,
            })}
          >
            {
              <Trans
                components={{
                  TermsAndConditionLink: <TermsAndConditionLink />,
                }}
                i18nKey="main:termsAndCondition:text"
              />
            }
          </span>
        </label>
      </div>
      <ul className="pay-now">
        <li>
          <label htmlFor="pay_paypal" className="radio-input-label">
            <input
              id="pay_paypal"
              type="radio"
              onChange={(e) => {
                handleRadioChange(e.currentTarget.value);
              }}
              checked={paymentRadio === "pay_paypal"}
              disabled={isDateEmpty}
              value="pay_paypal"
              name="Payment"
              className="radio-input"
            />
            <span className="paypal-span">PayPal</span>
            <img
              className="paypal-img"
              src="/assets/paypal-logo.png"
              alt="Paypal"
            />
          </label>
        </li>

        <li>
          <label htmlFor="booked" className="radio-input-label">
            <input
              id="booked"
              type="radio"
              onChange={(e) => handleRadioChange(e.currentTarget.value)}
              checked={paymentRadio === "booked"}
              disabled={isDateEmpty}
              value="booked"
              name="Payment"
              className="radio-input"
            />
            <span className="paypal-span">{t("main:booking")}</span>
          </label>
        </li>
      </ul>
      <div>
        {paymentRadio === "pay_paypal" ? (
          <div className="text-align-center">
            <PayPalButtons
              style={{
                color: "blue",
                layout: "horizontal",
                height: 48,
                tagline: false,
                shape: "pill",
              }}
              onInit={handlePaypalButtonInit}
              disabled={!checkValidation()}
              onClick={handlePaypalSubmit}
              forceReRender={[installationDetails, paymentRadio, grandTotal]}
              createOrder={(_, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      description: "product.description",
                      amount: {
                        value: grandTotal.toString(),
                      },
                    },
                  ],
                });
              }}
              onApprove={async (_, actions: any) => {
                const order = await actions.order.capture();
                handleApprove({ ...order, amount: grandTotal });
              }}
              onCancel={() => {
                showToast("paymentCancelled", "error");
              }}
              className="paypal-button"
            />
          </div>
        ) : null}

        <button
          className="payment-btns"
          onClick={() => {
            form.handleSubmit(() => {
              if (checkValidation()) {
                if (paymentRadio === "pay_paypal") {
                  // Incase of Paypal, form is submitted onApprove of payment
                } else {
                  handleApprove({ amount: grandTotal }, paymentRadio);
                }
              }
            })();
            changeShowRequired();
          }}
          disabled={global.isSubmitting}
        >
          {global.isSubmitting ? (
            <img src="/assets/loading-gif.gif" height="25px" alt="loading" />
          ) : paypalCorrelationID || paymentRadio !== "pay_paypal" ? (
            "Jetzt bestellen"
          ) : (
            <img src="/assets/loading-gif.gif" height="25px" alt="loading" />
          )}
        </button>
      </div>
    </>
  );
};

export { PaypalCheckoutButton };

const TermsAndConditionLink = () => {
  const { t } = useTranslation();
  return (
    <a
      href={TERMS_AND_CONDITION_URL}
      rel="noreferrer"
      target="_blank"
      onClick={(e) => e.stopPropagation()}
      className="terms-and-condition"
    >
      {t("main:termsAndCondition:label")}
    </a>
  );
};
