import React, { useState } from "react";
import classNames from "classnames";
import ReactSelect from "../common/react-select";
import styles from "./AnimatedSelect.module.css";

const AnimatedSelect = React.forwardRef<
  React.ComponentRef<typeof ReactSelect>,
  React.ComponentProps<typeof ReactSelect>
>(({ className, placeholder, id, onFocus, onBlur, value, ...props }, ref) => {
  const fallbackId = React.useId();
  const finalId = id ?? fallbackId;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const hasValue = !!value;

  return (
    <div
      className={classNames(styles["floating-select"], {
        [styles.focused]: isFocused || hasValue,
      })}
    >
      <ReactSelect
        ref={ref}
        id={finalId}
        placeholder=""
        value={value}
        className={className}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      <label htmlFor={finalId} className={styles["select-label"]}>
        {placeholder}
      </label>
      <fieldset
        className={styles["fieldset"]}
        aria-invalid={props["aria-invalid"]}
      >
        <legend>{placeholder}</legend>
      </fieldset>
    </div>
  );
});

AnimatedSelect.displayName = "AnimatedSelect";

export { AnimatedSelect };
